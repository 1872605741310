/* New CSS */

.navbar-aolis {
  width: 100%;
  height: 100px;
}

.navbar-logo-aolis {
  display: inline-block;
  width: fit-content;
  font-size: 34px;
  font-weight: 700;
  margin-top: 10px;
  margin-left: 30px;
  color: #1f1f1f;
  font-family: 'Oswald', sans-serif;
}

.navbar-logo-aolis:hover {
  text-decoration: none;
  color: #1f1f1f;
}

.navbar-button-1 {
  display: block;
  font-size: 26px;
  font-weight: 500;
  float: right;
  margin-top: 20px;
  margin-right: 30px;
  color: #1f1f1f;
}

.navbar-button-1:hover {
  text-decoration: none;
  color: #1f1f1f;
}

.navbar-button-2 {
  display: block;
  font-size: 21px;
  font-weight: 500;
  float: right;
  margin-top: 16px;
  margin-right: 25px;
  color: #1f1f1f;
}

.navbar-button-2:hover {
  text-decoration: none;
  color: #1f1f1f;
}

.navbar-button-3 {
  display: block;
  border: 0;
  background-color: #ffffff00;
  font-size: 21px;
  font-weight: 500;
  float: right;
  margin-top: 22px;
  margin-right: 30px;
  color: #1f1f1f;
}

.navbar-button-3:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #1f1f1f;
  border-radius: 5px;
}

.navbar-button-4 {
  display: block;
  border: 0;
  background-color: #ffffff00;
  font-size: 21px;
  font-weight: 500;
  float: right;
  margin-top: 22px;
  margin-right: 30px;
  color: #1f1f1f;
}

.navbar-button-4:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #1f1f1f;
  border-radius: 5px;
}

.navbar-button-5 {
  display: block;
  border: 0;
  background-color: #ffffff00;
  font-size: 21px;
  font-weight: 500;
  float: right;
  margin-top: 22px;
  margin-right: 30px;
  color: #1f1f1f;
}

.navbar-button-5:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #1f1f1f;
  border-radius: 5px;
}

.navbar-button-6 {
  display: none;
}

.fa-twitter-1 {
  font-size: 50px;
}

.fa-discord-1 {
  font-size: 50px;
}

.fa-twitter-1:hover {
  color: #545454;
}

.fa-discord-1:hover {
  color: #545454;
}

.aolis-1-div-1 {
  width: 100%;
  margin-top: 100px;
}

.aolis-1-div-1-1 {
  width: 50%;
  height: 1000px;
  float: left;
  text-align: center;
}

.aolis-1-div-1-1-1 {
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.aolis-1-div-1-2 {
  width: 50%;
  height: 1000px;
  float: left;
}

.aolis-1-div-1-2-1 {
  background-image: url("/static/images/aolis-1-bg-obj-1.svg");

  width: 50vw;
  min-height: 35vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 1;
  position: absolute;
}

.aolis-1-div-1-2-2 {
  background-image: url("/static/images/aolis-1-moving-obj-2.svg");

  width: 50vw;
  min-height: 40vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 20% 2%;
  background-repeat: no-repeat;
  background-size: 20%;

  z-index: 1;
  position: absolute;

  -ms-transform: rotate(-4deg); /* IE 9 */
  -webkit-transform: rotate(-4deg); /* Chrome, Safari, Opera */
  transform: rotate(-4deg);

  animation: rotationObj2 2s ease infinite;
  animation-delay: 0.2s;
}

@keyframes rotationObj2 {
  50% {transform: rotate(0deg);}
}

.aolis-1-div-1-2-3 {
  background-image: url("/static/images/aolis-1-moving-obj-3.svg");

  width: 50vw;
  min-height: 40vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 10% 25%;
  background-repeat: no-repeat;
  background-size: 30%;

  z-index: 1;
  position: absolute;

  -ms-transform: rotate(-5deg); /* IE 9 */
  -webkit-transform: rotate(-5deg); /* Chrome, Safari, Opera */
  transform: rotate(-5deg);

  animation: rotationObj3 2s ease infinite;
}

@keyframes rotationObj3 {
  50% {transform: rotate(0deg);}
}


.aolis-1-div-1-2-4 {

  background-image: url("/static/images/aolis-1-moving-obj-1.svg");

  width: 50vw;
  height: 40vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 50% 10%;
  background-repeat: no-repeat;
  background-size: 60%;

  z-index: 2;
  position: absolute;

  animation: MoveUpDown 2s ease infinite;
}

@keyframes MoveUpDown {
  0%, 100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 2vw;
  }
}

.aolis-1-div-1-2-5 {
  background-image: url("/static/images/aolis-1-moving-obj-4.svg");

  width: 50vw;
  height: 35vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 53% 36%;
  background-repeat: no-repeat;
  background-size: 55%;

  z-index: 2;
  position: absolute;

  animation: armsMoving 2s ease infinite;
}

@keyframes armsMoving {
  0%, 100% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(15deg);
  }
}

.aolis-1-div-1-2-6 {
  background-image: url("/static/images/aolis-1-static-obj-1.svg");

  width: 50vw;
  height: 35vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: 75%;

  z-index: 2;
  position: absolute;
}

.aolis-1-div-1-2-7 {

  background-image: url("/static/images/aolis-1-moving-obj-5.svg");

  width: 50vw;
  height: 20vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 78.8% 9.5%;
  background-repeat: no-repeat;
  background-size: 12%;

  z-index: 2;
  position: absolute;

  animation: moveHandUpDown 2s ease infinite;
}

@keyframes moveHandUpDown {
  0%, 100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 0.5vw;
  }
}

.aolis-1-div-1-2-8 {
  background-image: url("/static/images/aolis-1-static-obj-2.svg");

  width: 50vw;
  height: 20vw;

  margin-left: auto;
  margin-right: auto;

  /* Center and scale the image nicely */
  background-position: 80% 0%;
  background-repeat: no-repeat;
  background-size: 15%;

  z-index: 2;
  position: absolute;
}

.text-1-aolis {
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  margin-top: 8vw;
  font-family: 'Oswald', sans-serif;
}

.text-2-aolis {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
  font-family: 'Oswald', sans-serif;
}

.aolis-1-button-1 {
  margin-top: 15px;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;

  width: 270px;
  height: 55px;
  background-image: linear-gradient(to right, rgb(36, 145, 255) , rgb(0, 89, 255));
  border-radius: 10px;

  border-width: 1px !important;
}

.div-content-2-aolis {
  display: inline-block;
  width: 100%;
  height: 250px;
  background-color: rgb(0, 85, 255);
}

.p-content-2-aolis-1 {
  font-size: 30px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin-top: 40px;
  font-family: 'Oswald', sans-serif;
}

.p-content-2-aolis-2 {
  font-size: 26px;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
  font-family: 'Oswald', sans-serif;
}

.btn-content-2-aolis-1 {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700 !important;

  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.btn-content-2-aolis-1 {
  border-color: #ee7614;
  color: #ee7614;
}

.btn-content-2-aolis-1:hover {
  background-color: #ee7614;
  color: white;
}

.a-content-2-aolis-1:hover {
  text-decoration: none;
}

.div-content-3-aolis {
  margin-top: 300px;
}

.div-content-3-aolis-1 {
  width: 33%;
  height: 600px;
  float: left;
}

.div-content-3-aolis-2 {
  width: 33%;
  height: 600px;
  float: left;
}

.div-content-3-aolis-3 {
  width: 33%;
  height: 600px;
  float: left;
}

.div-content-3-aolis-1-1 {
  width: 400px;
  height: 600px;
  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;
}

.div-content-3-aolis-2-1 {
  width: 400px;
  height: 600px;
  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;
}

.div-content-3-aolis-3-1 {
  width: 400px;
  height: 600px;
  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;
}

.div-content-3-aolis-1-2 {
  width: 100%;
  height: 300px;

  margin-top: 0.5px;

  background-color: #1f1f1fb7;
  background-image: url("/static/images/aolis-obj-content-3-1.svg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.div-content-3-aolis-2-2 {
  width: 100%;
  height: 300px;

  margin-top: 0.5px;

  background-color: #1f1f1fb7;
  background-image: url("/static/images/aolis-obj-content-3-2.svg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
}

.div-content-3-aolis-3-2 {
  width: 100%;
  height: 300px;

  margin-top: 0.5px;

  background-color: #1f1f1fb7;
  background-image: url("/static/images/aolis-obj-content-3-3.svg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 85%;
}

.p-content-3-aolis-1 {
  text-align: center;
  width: 75%;

  font-size: 24px;
  font-weight: 500;

  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.p-content-3-aolis-2 {
  text-align: center;
  width: 75%;

  font-size: 20px;
  font-weight: 300;

  margin-left: auto;
  margin-right: auto;
}

.div-content-4-aolis {
  display: inline-block;
  width: 100%;
  height: 1850px;
  background-color: rgba(0, 110, 255, 0.102);
  margin-top: 300px;
}

.p-content-4-aolis-1 {
  text-align: center;
  margin-top: 150px;
  font-size: 45px;
  font-weight: 500;
  color: rgb(41, 41, 41);
  font-family: 'Oswald', sans-serif;
}

.div-content-4-aolis-1 {
  width: 800px;
  height: 300px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;

  background-color: #ffffff;

  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;
}

.div-content-4-aolis-2 {
  width: 800px;
  height: 300px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;

  background-color: #ffffff;

  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;
}

.div-content-4-aolis-3 {
  width: 800px;
  height: 300px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;

  background-color: #ffffff;

  border: 1px solid rgba(116, 116, 116, 0.702);
  border-radius: 10px;
}

.div-content-4-aolis-1-1 {
  width: 40%;
  height: 100%;
  float: left;
  background-color: #1f1f1fb7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.div-content-4-aolis-1-2 {
  width: 60%;
  float: right;
}

.div-content-4-aolis-2-1 {
  width: 40%;
  height: 100%;
  float: right;
  background-color: #1f6af597;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.div-content-4-aolis-2-2 {
  width: 60%;
  float: left;
}

.div-content-4-aolis-3-1 {
  width: 40%;
  height: 100%;
  float: left;
  background-color: #14d6ccc7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.div-content-4-aolis-3-2 {
  width: 60%;
  float: right;
}

.p-content-4-aolis-2 {
  text-align: center;
  color: #ffffff;
  font-size: 35px;
  font-weight: 400;
  margin-top: 117px;
  font-family: 'Oswald', sans-serif;
}

.p-content-4-aolis-3 {
  text-align: center;
  color: #2f2f2f;
  font-size: 27px;
  font-weight: 500;
  margin-top: 40px;
  font-family: 'Oswald', sans-serif;
}

.p-content-4-aolis-3-1 {
  text-align: center;
  color: #2f2f2f;
  font-size: 27px;
  font-weight: 500;
  margin-top: 45px;
  font-family: 'Oswald', sans-serif;
}

.p-content-4-aolis-4 {
  text-align: center;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #404040;
  font-size: 24px;
  font-weight: 300;
  font-family: 'Oswald', sans-serif;
}

.btn-content-4-aolis-1 {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700 !important;

  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.btn-content-4-aolis-1 {
  border-color: #323232;
  color: #323232;
}

.btn-content-4-aolis-1:hover {
  background-color: #323232;
  color: white;
}

.a-content-4-aolis-1:hover {
  text-decoration: none;
}

.div-content-5-aolis {
  width: 60%;
  height: 1000px;

  margin-left: auto;
  margin-right: auto;
}

.p-content-5-aolis-1 {
  text-align: center;
  margin-top: 150px;
  color: #222222;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
}

.div-content-5-aolis-1 {
  width: 50%;
  float: left;
  height: 600px;
}

.div-content-5-aolis-1-1 {
  width: 400px;
  height: 830px;

  border: 2px solid rgba(81, 81, 81, 0.78);
  border-radius: 10px;

  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.div-content-5-aolis-1-1-1 {
  width: 100%;
  height: 400px;

  margin-left: 0.3px;

  background-image: url("/static/images/Riccardo-Team.jpg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 99.9%;
}

.div-content-5-aolis-2 {
  width: 50%;
  float: right;
  height: 600px;
}

.div-content-5-aolis-2-1 {
  width: 400px;
  height: 830px;

  border: 2px solid rgba(81, 81, 81, 0.78);
  border-radius: 10px;

  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.div-content-5-aolis-2-1-1 {
  width: 100%;
  height: 400px;

  margin-left: 0.3px;

  background-image: url("/static/images/Nicolo-Team.jpg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 99.9%;
}

.div-content-5-aolis-3 {
  width: 50%;
  float: left;
  height: 600px;
  margin-top: 400px;
}

.div-content-5-aolis-3-1 {
  width: 400px;
  height: 830px;

  border: 2px solid rgba(81, 81, 81, 0.78);
  border-radius: 10px;

  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.div-content-5-aolis-3-1-1 {
  width: 99.8%;
  height: 400px;

  margin-left: 0.8px;

  background-image: url("/static/images/MeanGreenGod-Contributors.png");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div-content-5-aolis-4 {
  width: 50%;
  float: right;
  height: 600px;
  margin-top: 400px;
}

.div-content-5-aolis-4-1 {
  width: 400px;
  height: 830px;

  border: 2px solid rgba(81, 81, 81, 0.78);
  border-radius: 10px;

  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.div-content-5-aolis-4-1-1 {
  width: 100%;
  height: 400px;

  margin-left: 0.3px;

  background-image: url("/static/images/Nicolo-Team.jpg");

  border-top-left-radius: 10px;  
  border-top-right-radius: 10px;  

  background-position: center;
  background-repeat: no-repeat;
  background-size: 99.9%;
}

.p-content-5-aolis-2 {
  text-align: center;
  margin-top: 20px;
  color: #222222;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-2-1 {
  text-align: center;
  margin-top: 70px;
  color: #222222;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-2-2 {
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 220px;
  color: #222222;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-3 {
  text-align: center;
  margin-top: 5px;
  color: #505050;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-3-1 {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: #505050;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-4 {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  color: #505050;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-4-1 {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  color: #505050;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Oswald', sans-serif;
}

.p-content-5-aolis-4-2 {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  color: #505050;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Oswald', sans-serif;
}

.btn-content-5-aolis-1 {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700 !important;

  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.btn-content-5-aolis-1 {
  border-color: #f55d10;
  color: #f55d10;
}

.btn-content-5-aolis-1:hover {
  background-color: #f55d10;
  color: white;
}

.a-content-5-aolis-1:hover {
  text-decoration: none;
}

.div-content-6-aolis {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 500px;
  /*margin-top: 700px;*/
  background-color: #1f1f1f;
}

.div-content-6-aolis-1 {
  width: 50%;
  float: left;
}

.div-content-6-aolis-2 {
  width: 50%;
  float: right;
}

.p-content-6-aolis-1 {
  margin-top: 160px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Oswald', sans-serif;
}

.button-content-6-aolis-1 {
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;

  border: 0;
  font-size: 28px;
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
  background-color: #ffffff00;
}

.button-content-6-aolis-2 {
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  border: 0;
  font-size: 28px;
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
  background-color: #ffffff00;
}

.button-content-6-aolis-3 {
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  border: 0;
  font-size: 28px;
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
  background-color: #ffffff00;
}

.fa-twitter-2 {
  font-size: 70px;
  color: #ffffff;
  margin-top: 20px;
}

.fa-discord-2 {
  font-size: 70px;
  color: #ffffff;
  margin-top: 20px;
}

@media screen and (max-width: 1600px) {

  .aolis-1-div-1 {
    width: 100%;
    margin-top: 20px;
  }

  .aolis-1-div-1-1 {
    width: 50%;
    height: 500px;
    margin-top: 10vw;
    float: left;
    text-align: center;
  }
  
  .aolis-1-div-1-1-1 {
    width: 33vw;
    margin-left: auto;
    margin-right: auto;
  }

  .text-1-aolis {
    font-size: 27px;
    text-align: center;
    font-weight: 500;
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
  }

  .text-2-aolis {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    margin-top: 1vw;
    font-family: 'Oswald', sans-serif;
  }

  .aolis-1-button-1 {
    margin-top: 15px;
    font-size: 22px;
    color: #ffffff;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;

    width: 220px;
    height: 50px;
    background-image: linear-gradient(to right, rgb(36, 145, 255) , rgb(0, 89, 255));
    border-radius: 10px;
  
    border-width: 1px !important;
  }

  .aolis-1-div-1-2 {
    margin-top: 125px;
    width: 50%;
    height: 500px;
    float: left;
  }

  .div-content-2-aolis {
    display: inline-block;
    margin-top: 20vw;
    width: 100%;
    height: 400px;
    background-color: rgb(0, 85, 255);
  }

  .p-content-2-aolis-1 {
    font-size: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    margin-top: 90px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-2-aolis-2 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    font-family: 'Oswald', sans-serif;
  }
  
  .btn-content-2-aolis-1 {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700 !important;
  
    display: block;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-content-2-aolis-1 {
    border-color: #ee7614;
    color: #ee7614;
  }
  
  .btn-content-2-aolis-1:hover {
    background-color: #ee7614;
    color: white;
  }
  
  .a-content-2-aolis-1:hover {
    text-decoration: none;
  }

  .div-content-3-aolis-1-1 {
    width: 300px;
    height: 680px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-2-1 {
    width: 300px;
    height: 680px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-3-1 {
    width: 300px;
    height: 680px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }

  .div-content-5-aolis {
    width: 80%;
    height: 1000px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .p-content-5-aolis-1 {
    text-align: center;
    margin-top: 150px;
    color: #222222;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
  }
  
  .div-content-5-aolis-1 {
    width: 50%;
    float: left;
    height: 600px;
  }
  
  .div-content-5-aolis-1-1 {
    width: 33.5vw;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-1-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Riccardo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-2 {
    width: 50%;
    float: right;
    height: 600px;
  }
  
  .div-content-5-aolis-2-1 {
    width: 33.5vw;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-2-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-3 {
    width: 50%;
    float: left;
    height: 600px;
    margin-top: 400px;
  }
  
  .div-content-5-aolis-3-1 {
    width: 33.5vw;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-3-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.8px;
  
    background-image: url("/static/images/MeanGreenGod-Contributors.png");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .div-content-5-aolis-4 {
    width: 50%;
    float: right;
    height: 600px;
    margin-top: 400px;
  }
  
  .div-content-5-aolis-4-1 {
    width: 33.5vw;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-4-1-1 {
    width: 100%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .p-content-5-aolis-2 {
    text-align: center;
    margin-top: 3vw;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-2-1 {
    text-align: center;
    margin-top: 5vw;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-2-2 {
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 220px;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-3 {
    text-align: center;
    margin-top: 5px;
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-3-1 {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-4 {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    color: #505050;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-4-1 {
    text-align: center;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: #505050;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }

}

@media screen and (max-width: 1050px) {

  .text-1-aolis {
    font-size: 2.7vw;
    text-align: center;
    font-weight: 500;
    margin-top: 60px;
    font-family: 'Oswald', sans-serif;
  }

  .text-2-aolis {
    font-size: 2vw;
    text-align: center;
    font-weight: 400;
    margin-top: 1vw;
    font-family: 'Oswald', sans-serif;
  }

  .aolis-1-button-1 {
    margin-top: 15px;
    font-size: 2.3vw;
    color: #ffffff;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;

    width: 220px;
    height: 50px;
    background-image: linear-gradient(to right, rgb(36, 145, 255) , rgb(0, 89, 255));
    border-radius: 10px;
  
    border-width: 1px !important;
  }


  .aolis-1-div-1-2 {
    margin-top: 150px;
    width: 50%;
    height: 500px;
    float: left;
  }

  .div-content-2-aolis {
    display: inline-block;
    margin-top: 50px;
    width: 100%;
    height: 400px;
    background-color: rgb(0, 85, 255);
  }

  .p-content-2-aolis-1 {
    font-size: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    margin-top: 70px;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-2-aolis-2 {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    font-family: 'Oswald', sans-serif;
  }

  .div-content-3-aolis-1 {
    width: 100%;
    height: 600px;
  }
  
  .div-content-3-aolis-2 {
    width: 100%;
    height: 600px;
    margin-top: 120px;
  }
  
  .div-content-3-aolis-3 {
    width: 100%;
    height: 600px;
    margin-top: 120px;
  }

  .div-content-3-aolis-1-1 {
    width: 450px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-2-1 {
    width: 450px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-3-1 {
    width: 450px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }

  .div-content-3-aolis-1-2 {
    width: 100%;
    height: 300px;
  
    margin-top: 0.5px;
  
    background-color: #1f1f1fb7;
    background-image: url("/static/images/aolis-obj-content-3-1.svg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%;
  }

  .p-content-4-aolis-1 {
    text-align: center;
    margin-top: 150px;
    font-size: 35px;
    font-weight: 500;
    color: rgb(41, 41, 41);
    font-family: 'Oswald', sans-serif;
  }

  .div-content-4-aolis-1 {
    width: 600px;
    height: 300px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }
  
  .div-content-4-aolis-2 {
    width: 600px;
    height: 300px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }
  
  .div-content-4-aolis-3 {
    width: 600px;
    height: 300px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }

  .p-content-4-aolis-2 {
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
    margin-top: 117px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-3 {
    text-align: center;
    color: #2f2f2f;
    font-size: 23px;
    font-weight: 500;
    margin-top: 40px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-3-1 {
    text-align: center;
    color: #2f2f2f;
    font-size: 23px;
    font-weight: 500;
    margin-top: 45px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-4 {
    text-align: center;
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #404040;
    font-size: 21px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }
  
  .btn-content-4-aolis-1 {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700 !important;
  
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-content-4-aolis-1 {
    border-color: #323232;
    color: #323232;
  }
  
  .btn-content-4-aolis-1:hover {
    background-color: #323232;
    color: white;
  }
  
  .a-content-4-aolis-1:hover {
    text-decoration: none;
  }

  .div-content-5-aolis {
    width: 80%;
    height: 1000px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .p-content-5-aolis-1 {
    text-align: center;
    margin-top: 150px;
    color: #222222;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
  }
  
  .div-content-5-aolis-1 {
    width: 100%;
    height: 830px;
  }
  
  .div-content-5-aolis-1-1 {
    width: 60%;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-1-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Riccardo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-2 {
    width: 100%;
    height: 830px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-2-1 {
    width: 60%;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-2-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-3 {
    width: 100%;
    height: 830px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-3-1 {
    width: 60%;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-3-1-1 {
    width: 99.8%;
    height: 350px;
  
    margin-left: 0.8px;
  
    background-image: url("/static/images/MeanGreenGod-Contributors.png");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .div-content-5-aolis-4 {
    width: 100%;
    height: 830px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-4-1 {
    width: 60%;
    height: 830px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-4-1-1 {
    width: 100%;
    height: 350px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .p-content-5-aolis-2 {
    text-align: center;
    margin-top: 3vw;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-2-1 {
    text-align: center;
    margin-top: 5vw;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-2-2 {
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 220px;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-3 {
    text-align: center;
    margin-top: 5px;
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-3-1 {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-4 {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    color: #505050;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-5-aolis-4-1 {
    text-align: center;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: #505050;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }

}

@media screen and (max-width: 768px) {

  .navbar-button-1 {
    display: none;
  }
  
  .navbar-button-2 {
    display: none;
  }
  
  .navbar-button-3 {
    display: none;
  }
  
  .navbar-button-4 {
    display: none;
  }
  
  .navbar-button-5 {
    display: none;
  }

  .navbar-button-6 {
    display: block;
    background-color: #ffffff00;
    border: 0;
    font-size: 26px;
    float: right;
    margin-top: 20px;
    margin-right: 30px;
    color: #1f1f1f;
  }
  
  .navbar-button-6:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #1f1f1f;
  }

  .div-dropdown-aolis-1 {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
  }

  .div-dropdown-aolis-2 {
    display: inline-block;
    height: 100%;
    width: 60%;
    background-color: #1f1f1f;
  }

  .div-dropdown-aolis-3 {
    width: 55%;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-button-1-1 {
    display: block;
    width: 50px;
    font-size: 26px;
    font-weight: 500;
    float: left;
    color: #ffffff;
  }
  
  .navbar-button-1-1:hover {
    text-decoration: none;
    color: #c3c3c3;
  }
  
  .navbar-button-2-1 {
    display: block;
    width: 50px;
    font-size: 21px;
    font-weight: 500;
    float: right;
    color: #ffffff;
  }
  
  .navbar-button-2-1:hover {
    text-decoration: none;
    color: #c3c3c3;
  }
  
  .navbar-button-3-1 {
    display: block;
    border: 0;
    background-color: #ffffff00;
    font-size: 29px;
    font-weight: 500;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
  }
  
  .navbar-button-3-1:hover {
    text-decoration: none;
    color: #c3c3c3;
    border-radius: 5px;
  }
  
  .navbar-button-4-1 {
    display: block;
    border: 0;
    background-color: #ffffff00;
    font-size: 29px;
    font-weight: 500;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
  }
  
  .navbar-button-4-1:hover {
    text-decoration: none;
    color: #c3c3c3;
    border-radius: 5px;
  }
  
  .navbar-button-5-1 {
    display: block;
    border: 0;
    background-color: #ffffff00;
    font-size: 29px;
    font-weight: 500;
    margin-top: 180px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
  }
  
  .navbar-button-5-1:hover {
    text-decoration: none;
    color: #c3c3c3;
    border-radius: 5px;
  }

  .aolis-1-div-1 {
    width: 100%;
    margin-top: 10px;
  }

  .aolis-1-div-1-1 {
    width: 100%;
    height: 300px;
    margin-top: 230px;
    position: relative;
    text-align: center;
  }

  .text-1-aolis {
    font-size: 21px;
    text-align: center;
    font-weight: 600;
    margin-top: 60px;
    font-family: 'Oswald', sans-serif;
  }

  .text-2-aolis {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-top: 1vw;
    font-family: 'Oswald', sans-serif;
  }

  .aolis-1-button-1 {
    margin-top: 15px;
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;

    width: 180px;
    height: 45px;
    background-image: linear-gradient(to right, rgb(36, 145, 255) , rgb(0, 89, 255));
    border-radius: 10px;
  
    border-width: 1px !important;
  }
  
  .aolis-1-div-1-1-1 {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .aolis-1-div-1-2 {
    width: 320px;
    height: 320px;
    position: absolute;

    display: inline-block;
    margin-top: 20px;
    
    left: 50%;
    margin-left: -160px;
  }
  
  .aolis-1-div-1-2-1 {
    background-image: url("/static/images/aolis-1-bg-obj-1.svg");
  
    width: 100%;
    min-height: 63%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  
    z-index: 1;
    position: absolute;
  }
  
  .aolis-1-div-1-2-2 {
    background-image: url("/static/images/aolis-1-moving-obj-2.svg");
  
    width: 90%;
    min-height: 75%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 20% 2%;
    background-repeat: no-repeat;
    background-size: 20%;
  
    z-index: 1;
    position: absolute;
  
    -ms-transform: rotate(-4deg); /* IE 9 */
    -webkit-transform: rotate(-4deg); /* Chrome, Safari, Opera */
    transform: rotate(-4deg);
  
    animation: rotationObj2 2s ease infinite;
    animation-delay: 0.2s;
  }
  
  @keyframes rotationObj2 {
    50% {transform: rotate(0deg);}
  }
  
  .aolis-1-div-1-2-3 {
    background-image: url("/static/images/aolis-1-moving-obj-3.svg");
  
    width: 100%;
    min-height: 75%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 10% 25%;
    background-repeat: no-repeat;
    background-size: 30%;
  
    z-index: 1;
    position: absolute;
  
    -ms-transform: rotate(-5deg); /* IE 9 */
    -webkit-transform: rotate(-5deg); /* Chrome, Safari, Opera */
    transform: rotate(-5deg);
  
    animation: rotationObj3 2s ease infinite;
  }
  
  @keyframes rotationObj3 {
    50% {transform: rotate(0deg);}
  }
  
  
  .aolis-1-div-1-2-4 {
  
    background-image: url("/static/images/aolis-1-moving-obj-1.svg");
  
    width: 100%;
    height: 75%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 50% 10%;
    background-repeat: no-repeat;
    background-size: 60%;
  
    z-index: 2;
    position: absolute;
  
    animation: MoveUpDown 2s ease infinite;
  }
  
  @keyframes MoveUpDown {
    0%, 100% {
      margin-top: 0px;
    }
    50% {
      margin-top: 2vw;
    }
  }
  
  .aolis-1-div-1-2-5 {
    background-image: url("/static/images/aolis-1-moving-obj-4.svg");
  
    width: 100%;
    height: 70%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 53% 36%;
    background-repeat: no-repeat;
    background-size: 55%;
  
    z-index: 2;
    position: absolute;
  
    animation: armsMoving 2s ease infinite;
  }
  
  @keyframes armsMoving {
    0%, 100% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(15deg);
    }
  }
  
  .aolis-1-div-1-2-6 {
    background-image: url("/static/images/aolis-1-static-obj-1.svg");
  
    width: 100%;
    height: 70%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 50% 75%;
    background-repeat: no-repeat;
    background-size: 75%;
  
    z-index: 2;
    position: absolute;
  }
  
  .aolis-1-div-1-2-7 {
  
    background-image: url("/static/images/aolis-1-moving-obj-5.svg");
  
    width: 100%;
    height: 38%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 78.8% 9.5%;
    background-repeat: no-repeat;
    background-size: 12%;
  
    z-index: 2;
    position: absolute;
  
    animation: moveHandUpDown 2s ease infinite;
  }
  
  @keyframes moveHandUpDown {
    0%, 100% {
      margin-top: 0px;
    }
    50% {
      margin-top: 0.5vw;
    }
  }
  
  .aolis-1-div-1-2-8 {
    background-image: url("/static/images/aolis-1-static-obj-2.svg");
  
    width: 100%;
    height: 38%;
  
    margin-left: auto;
    margin-right: auto;
  
    /* Center and scale the image nicely */
    background-position: 80% 0%;
    background-repeat: no-repeat;
    background-size: 15%;
  
    z-index: 2;
    position: absolute;
  }

  .div-content-2-aolis {
    display: inline-block;
    margin-top: 200px;
    width: 100%;
    height: 400px;
    background-color: rgb(0, 85, 255);
  }

  .p-content-2-aolis-1 {
    font-size: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    margin-top: 50px;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-2-aolis-2 {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-3-aolis-1 {
    text-align: center;
    width: 75%;
  
    font-size: 22px;
    font-weight: bold;
  
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .p-content-3-aolis-2 {
    text-align: center;
    width: 85%;
  
    font-size: 18px;
    font-weight: 300;
  
    margin-left: auto;
    margin-right: auto;
  }

  .div-content-3-aolis-1 {
    width: 100%;
    height: 600px;
  }
  
  .div-content-3-aolis-2 {
    width: 100%;
    height: 600px;
    margin-top: 120px;
  }
  
  .div-content-3-aolis-3 {
    width: 100%;
    height: 600px;
    margin-top: 120px;
  }

  .div-content-3-aolis-1-1 {
    width: 300px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-2-1 {
    width: 300px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-3-aolis-3-1 {
    width: 300px;
    height: 600px;
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  
    margin-left: auto;
    margin-right: auto;
  }

  .div-content-4-aolis {
    display: inline-block;
    width: 100%;
    height: 2450px;
    background-color: rgba(0, 110, 255, 0.102);
    margin-top: 300px;
  }
  
  .p-content-4-aolis-1 {
    text-align: center;
    margin-top: 150px;
    font-size: 35px;
    font-weight: 500;
    color: rgb(41, 41, 41);
    font-family: 'Oswald', sans-serif;
  }
  
  .div-content-4-aolis-1 {
    width: 300px;
    height: 500px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }
  
  .div-content-4-aolis-2 {
    width: 300px;
    height: 500px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }
  
  .div-content-4-aolis-3 {
    width: 300px;
    height: 500px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #ffffff;
  
    border: 1px solid rgba(116, 116, 116, 0.702);
    border-radius: 10px;
  }
  
  .div-content-4-aolis-1-1 {
    width: 100%;
    height: 40%;
    background-color: #1f1f1fb7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }
  
  .div-content-4-aolis-1-2 {
    width: 100%;
    height: 60%;
  }
  
  .div-content-4-aolis-2-1 {
    width: 100%;
    height: 40%;
    background-color: #1f6af597;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
  }
  
  .div-content-4-aolis-2-2 {
    width: 100%;
    height: 60%;
  }
  
  .div-content-4-aolis-3-1 {
    width: 100%;
    height: 40%;
    background-color: #14d6ccc7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }
  
  .div-content-4-aolis-3-2 {
    width: 100%;
    height: 60%;
  }
  
  .p-content-4-aolis-2 {
    text-align: center;
    color: #ffffff;
    font-size: 28px;
    font-weight: 400;
    margin-top: 80px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-3 {
    text-align: center;
    color: #2f2f2f;
    font-size: 21px;
    font-weight: 500;
    margin-top: 40px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-3-1 {
    text-align: center;
    color: #2f2f2f;
    font-size: 21px;
    font-weight: 500;
    margin-top: 55px;
    font-family: 'Oswald', sans-serif;
  }
  
  .p-content-4-aolis-4 {
    text-align: center;
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #404040;
    font-size: 19px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }
  
  .btn-content-4-aolis-1 {
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 19px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700 !important;
  
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-content-4-aolis-1 {
    border-color: #323232;
    color: #323232;
  }
  
  .btn-content-4-aolis-1:hover {
    background-color: #323232;
    color: white;
  }
  
  .a-content-4-aolis-1:hover {
    text-decoration: none;
  }

  .div-content-5-aolis {
    width: 320px;
    height: 1000px;
  
    margin-left: auto;
    margin-right: auto;
  }

  .div-content-5-aolis-1 {
    width: 100%;
    height: 620px;
  }
  
  .div-content-5-aolis-1-1 {
    width: 100%;
    height: 620px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-1-1-1 {
    width: 99.8%;
    height: 250px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Riccardo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-2 {
    width: 100%;
    height: 620px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-2-1 {
    width: 100%;
    height: 620px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-2-1-1 {
    width: 99.8%;
    height: 250px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }
  
  .div-content-5-aolis-3 {
    width: 100%;
    height: 620px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-3-1 {
    width: 100%;
    height: 620px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-3-1-1 {
    width: 99.8%;
    height: 250px;
  
    margin-left: 0.8px;
  
    background-image: url("/static/images/MeanGreenGod-Contributors.png");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .div-content-5-aolis-4 {
    width: 100%;
    height: 620px;
    margin-top: 150px;
  }
  
  .div-content-5-aolis-4-1 {
    width: 100%;
    height: 620px;
  
    border: 2px solid rgba(81, 81, 81, 0.78);
    border-radius: 10px;
  
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .div-content-5-aolis-4-1-1 {
    width: 100%;
    height: 250px;
  
    margin-left: 0.3px;
  
    background-image: url("/static/images/Nicolo-Team.jpg");
  
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;  
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 99.9%;
  }

  .p-content-5-aolis-2-1 {
    text-align: center;
    margin-top: 50px;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-5-aolis-2-2 {
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-5-aolis-4 {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    color: #505050;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-5-aolis-4-1 {
    text-align: center;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    color: #505050;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
  }

  .div-content-6-aolis {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 500px;
    margin-top: 300px;
    background-color: #1f1f1f;
  }

  .p-content-6-aolis-1 {
    margin-top: 160px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
  }

  .p-content-6-aolis-2 {
    margin-top: 160px;
  }

  .fa-twitter-2 {
    font-size: 50px;
    color: #ffffff;
    margin-top: 20px;
  }
  
  .fa-discord-2 {
    font-size: 50px;
    color: #ffffff;
    margin-top: 20px;
  }

  .button-content-6-aolis-1 {
    display: block;
  
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
  
    border: 0;
    font-size: 24px;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    background-color: #ffffff00;
  }

  .button-content-6-aolis-2 {
    display: block;
  
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  
    border: 0;
    font-size: 24px;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    background-color: #ffffff00;
  }
  
  .button-content-6-aolis-3 {
    display: block;
  
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  
    border: 0;
    font-size: 24px;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    background-color: #ffffff00;
  }

}


